<template>
  <div class="album">
    <div class="category pc">
      <img src="../assets/img/logo/logo-category.svg" alt="" />
      <a class="before" href="#">Trang chủ</a>
      <span class="tag-mid">/</span>
      <a class="after" href="#">Thư Viện</a>
    </div>
    <div class="album-main" v-if="album">
      <div class="album-main__title" data-aos="fade-up" v-if="album?.title">
        <h5>Thư Viện</h5>
        <h1 v-html="album.title[locale].title"></h1>
      </div>
      <div class="album-main__content" v-if="album?.libraries && album.libraries.length > 0">
        <div
          class="item"
          data-aos="fade-up"
          v-for="(item, index) in album.libraries"
          :key="index"
          @click="setActive(index)"
        >
          <router-link :to="'/album/' + item?.slug + '?id=' + item.folder_id">
            <div class="item__bg">
              <img :src="item.background" :alt="item[locale].title" />
            </div>
            <div class="item__content">
              <p>{{ item[locale].name }}</p>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LibrariesService from "@/services/LibrariesService";
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  data() {
    return {
      album: [],
    };
  },
  mounted() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    this.dataAlbum();
    // this.setMenu(5, true);
  },
  computed: {
    ...mapGetters(["locale"]),
  },
  methods: {
    ...mapActions(["setMenu"]),
    ...mapMutations(["setTitleAlbum"]),
    setActive(index) {
      this.$store.commit("setMenuActive", 5);
      this.$store.commit("setSubActive", index);
    },
    async dataAlbum() {
      await LibrariesService.getAlbum()
        .then((resp) => {
          this.album = resp.data.data;
          this.setTitleAlbum(this.album.libraries);
        })
        .catch(() => {});
    },
  },
};
</script>
